const CryptoJS = require('crypto-js');

/** AES算法的ECB模式加密-设置秘钥
 * https://www.csdn.net/tags/NtTaAgxsNzI0MTQtYmxvZwO0O0OO0O0O.html
 * */

/** 解密方法
 * @return {string}
 */
const key = CryptoJS.enc.Utf8.parse("a177828829081ab7");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('385f33cb91484b04');   //十六位十六进制数作为密钥偏移量
function aesDecrypt(word) {
    if(!word) return
    const decrypt = CryptoJS.AES.decrypt(word, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrypt.toString(CryptoJS.enc.Utf8)
}

/** 加密方法
 * @return {string}
 */
function aesEncrypt(word) {
    if(!word) return
    let encrypted = CryptoJS.AES.encrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}

module.exports={
    aesDecrypt:aesDecrypt,
    aesEncrypt:aesEncrypt
}
