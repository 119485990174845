<template>
  <div class="root">
    <div class="title">修改密码</div>

    <div class="content">
        <el-form :model="form" :rules="rules"  ref="ruleFormRef" >
          <!--原密码-->
           <el-form-item  class="form-item" prop="originPassword" style="margin-top:10px;">
              <el-input placeholder="原密码"   v-model="form.originPassword"  type="password"  auto-complete="new-password"/>
           </el-form-item>

          <!--新密码-->
          <el-form-item  class="form-item" prop="newPassword">
            <el-input placeholder="新密码"   v-model="form.newPassword" type="password"  auto-complete="new-password" />
          </el-form-item>

          <!--确认密码-->
          <el-form-item class="form-item" prop="sureassword">
            <el-input placeholder="确认密码"   v-model="form.sureassword" type="password"  auto-complete="new-password" />
          </el-form-item>

          <el-form-item  style="width:100%;" class="dialog-footer">
              <el-button type="primary" size="small" @click="sureDialog('ruleFormRef')"   style="margin-left: 130px;">确定</el-button>
          </el-form-item>

        </el-form>

    </div>

  </div>
</template>

<script>
import aes from "../../utils/aesEncrypt";

export default {
  name: "updatePassword",
  data:function () {
    let validatePass = (rule, value, callback) => {
        let patter=/[^\u4E00-\u9FA5]/g
        if(!patter.test(value)){
          callback(new Error('不允许输入汉字'));
        }
     }
    return {
       form:{
           originPassword:'',
           newPassword:'',
           sureassword:'',
       },
       rules:{
           originPassword: [
               { required: true, message: '请输入原密码', trigger: 'blur' },
               { min:8,max:20, message: '最小输入8位原密码',trigger: 'blur' },
               { validator: validatePass, trigger: 'blur' }
           ],
           newPassword: [
               { required: true, message: '请输入新密码', trigger: 'blur' },
               { min:8,max:20, message: '最小输入8位新密码',trigger: 'blur' },
               { validator: validatePass, trigger: 'blur' }
           ],
           sureassword: [
               { required: true, message: '请输入确认密码', trigger: 'blur' },
               { min:8,max:20, message: '最小输入8位确认密码',trigger: 'blur' },
               { validator: validatePass, trigger: 'blur' }
           ]
       }
    }
  },
  mounted() {

  },
  methods:{
   //确认密码
   sureDialog:function (formName) {
        console.log(formName,this.form)
       if(formName!==undefined){
           let that=this
           let myParsms={
               account:this.$commonUtils.getSessionData(this.$constant.account),
               newPassword:aes.aesEncrypt(that.form.newPassword),
               originPassword:aes.aesEncrypt(that.form.originPassword),
               sureassword:aes.aesEncrypt(that.form.sureassword),
           }
           if(myParsms.newPassword!==myParsms.sureassword){
               this.$message.error("新密码和确认密码不一致！")
               return
           }
           this.$post(this.$urlUtils.updatePassword,myParsms).then(res => {
               console.log(res)
               if(res.status===200){
                   this.$message.success(res.msg)
                   this.$commonUtils.setSessionData(this.$constant.account,'')
                   this.$commonUtils.setSessionData(this.$constant.loginData,'')
                   this.$router.replace({
                       name: 'login'
                   })
               }else{
                   this.$message.error(res.msg)
               }
           })
       }

    }
  }
}
</script>

<style scoped>
   @import "../../assets/css/global.css";
  .root{
    background: #ACE1F5;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .title{
    color: #036EB8;
    font-size: 18px;
    padding: 20px;
  }
  .content{
    margin-top: 50px;

  }
  .form-item{
    width: 300px;
  }

</style>
